import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Share from '../../../components/Share';
import ContactForm from '../../../components/ContactForm';
import Checkout from "../../../components/Checkout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <Row mdxType="Row">
        <Column colMd={1} colLg={1} mdxType="Column">
        </Column>
        <Column colMd={6} colLg={10} mdxType="Column">
          <p>{`ATM Whisperer for NinjaTrader 8`}</p>
          <p>{`Introducing the `}<strong parentName="p">{`ATM Whisperer`}</strong>{`, an advanced order placement tool developed for traders using the NinjaTrader 8 platform. Upgrade your trading experience with this innovative tool that lets you create orders automatically at the specific price defined by a selected indicator. ATM Whisperer improves and expands upon the built-in “Attach to” functionality, offering a range of advanced features for seamless and precise order management.`}</p>
        </Column>
        <br />
      </Row>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={5} mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          width: "100%",
          padding: "1rem 10%"
        }}>
          <img {...{
            "src": "/images/whisperer-ct.png",
            "alt": "chart-example"
          }}></img>
        </div>
      </Column>
      <Column colMd={3} colLg={5} mdxType="Column">
        <div style={{
          space: "0"
        }}>
One license per machine:
          <br />
          <Checkout id="2" lnk="https://buy.stripe.com/4gw00S7OtbOCbmgfZ6" mdxType="Checkout"><div style={{
              fontSize: "1.5rem"
            }}> $10 / month</div><s style={{
              color: '#999999'
            }}>&nbsp;$15 / month&nbsp;</s><br />billed annually</Checkout>
          <br />
          <br />
          <Checkout id="2" lnk="https://buy.stripe.com/8wM00Sb0FdWKgGAdQZ" mdxType="Checkout"><div style={{
              fontSize: "1.5rem"
            }}> $15 / month</div><s style={{
              color: '#999999'
            }}>&nbsp;$20 / month&nbsp;</s><br />billed monthly</Checkout>
          <br />
          <br />
          <a href="https://billing.stripe.com/p/login/dR65of0AH3SrcUgbII">Manage Your Subscription</a>
          <br />
          <br />
          <p>{`By purchasing from this website, you acknowledge that you understand and accept the following agreements:`}</p>
          <ul>
            <li parentName="ul">{`No Refunds Policy;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/terms/"
              }}>{`Terms and Conditions`}</a>{`;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/risk/"
              }}>{`Full Risk Disclosure`}</a>{`;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/software/"
              }}>{`Software Agreement`}</a>{`.`}<br />
              <br />
              <br />
              <div style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#343444',
                padding: '1rem',
                width: '100%'
              }}>
  <a href="https://drive.google.com/uc?id=1f-EF4oOKrzCIe3yrWRW0KBepr0LyEEN3&export=download" style={{
                  color: '#fff',
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center'
                }}>
    <div style={{
                    width: '30px',
                    marginRight: '1rem'
                  }}>
      <img src="/images/download-blue.png" alt="download-blue" title="download-blue" style={{
                      width: '100%'
                    }} />
    </div>
    <div>
      Download - First time installed will work as a trial for 7 days.
    </div>
  </a>
              </div></li>
          </ul>
        </div>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={4} colLg={7} mdxType="Column">
        <h2>{`Key Features`}</h2>
        <ul>
          <li parentName="ul">{`Automatic order creation for increased efficiency`}</li>
          <li parentName="ul">{`Intelligent order type selection (stop or limit)`}</li>
          <li parentName="ul">{`Utilizes current ChartTrader selections to create ATM strategy instances`}</li>
          <li parentName="ul">{`Multiple instances can be added and controlled on the same chart`}</li>
          <li parentName="ul">{`Easy access and control from the Chart Trader panel`}</li>
          <li parentName="ul">{`Ability to read indicator values a number of bars ago, ensuring accurate order placements`}</li>
        </ul>
      </Column>
      <Column colMd={3} colLg={3} mdxType="Column">
        <h3>{`Installation Guide`}</h3>
        <div style={{
          justifyContent: 'flex-end',
          display: "flex"
        }}>
          <br />
          <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/uvMaDVTYCCo?playsinline=1" frameBorder="0" allowFullScreen />
        </div>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={6} colLg={10} mdxType="Column">
        <h2>{`Customize Your Trading Experience`}</h2>
        <p>{`With ATM Whisperer, enjoy the freedom to easily add multiple instances on a single chart and control every aspect of your order placement and management. Leverage the power of automatic order creation and intelligent order type selection for a more efficient and effective trading experience.`}</p>
        <h2>{`Seamlessly Integrate with ChartTrader`}</h2>
        <p>{`ATM Whisperer is designed to work in harmony with the NinjaTrader 8 ChartTrader panel. Control and manage your orders directly from the Chart Trader panel, making your trading experience smoother and more streamlined.`}</p>
        <h2>{`Get Started Today`}</h2>
        <p>{`Elevate your trading with ATM Whisperer for NinjaTrader 8. Discover the benefits of precise, automated order placement and enhance the control you have over your trading process.`}</p>
        <p>{`Don’t miss out on this versatile and essential tool for successful trading – `}<strong parentName="p"><a parentName="strong" {...{
              "href": "#"
            }}>{`Get the ATM Whisperer now!`}</a></strong></p>
        <Share mdxType="Share" />
        <h3>{`Feedback`}</h3>
        <hr></hr>
      </Column>
    </Row>
    <br /><br />
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={6} mdxType="Column">
        <p>{`Do you have questions or feedback?`}</p>
        <p>{`Need technology or a new concept for your business? `}</p>
        <p>{`Contact us, and our team will get back to you.`}</p>
      </Column>
      <Column colMd={3} colLg={4} mdxType="Column">
Please fill out the form below, and let us discuss your idea. Your details will be kept confidential.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      